import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import Button from "../button/button";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';

import Pohja1 from "../../../images/carousel/jungmann-pohjakuva-esimerkkiasunto-27m2.svg"
import Pohja2 from "../../../images/carousel/jungmann-pohjakuva-esimerkkiasunto-41m2.svg"
import Pohja3 from "../../../images/carousel/jungmann-pohjakuva-esimerkkiasunto-60m2.svg"

import ArrowLeft from "../../../images/carousel/jungmann-swiper-button-prev.svg"
import ArrowRight from "../../../images/carousel/jungmann-swiper-button-next.svg"

import ImageArrowLeft from "../../../images/carousel/jungmann-swiper-images-left.svg"
import ImageArrowRight from "../../../images/carousel/jungmann-swiper-images-right.svg"

import "./image-text-carousel.scss";

const ImageTextCarousel = (props) => {
	const { data } = props;
	
	const blockClasses = [];

	const classes = `block block-image-text-carousel ${[...blockClasses]}`;
	
	const pohjakuvaCarouselItems = [
		{ image: Pohja1, caption: "Esimerkkiasunto 1h+kt 27 m²" },
		{ image: Pohja2, caption: "Esimerkkiasunto 1h+kt+al 41 m²" },
		{ image: Pohja3, caption: "Esimerkkiasunto 3h+kt 60,5 m²" },
	];

	const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
	
	const navigationImagesPrevRef = React.useRef(null);
	const navigationImagesNextRef = React.useRef(null);

	return(
		<section className={classes} id="vuokra-asunnot">
			<div className="wrap">
				<h2 dangerouslySetInnerHTML={{ __html: data.title }}></h2>
				<p className="description has-font-style-body-medium">{ data.text }</p>

				<div class="image-text-image">
					<div className="image-text-image__left">
						<figure>
							<GatsbyImage image={data.textImageText.left.image.childImageSharp.gatsbyImageData} alt="" />
							<figcaption dangerouslySetInnerHTML={{ __html: data.textImageText.left.caption }}></figcaption>
						</figure>
					</div>

					<div class="image-text-image__right">

						<div class="top">
							<div class="text" dangerouslySetInnerHTML={{ __html: data.textImageText.text.text }}></div>
							<Button text={data.textImageText.text.button.text} url={data.textImageText.text.button.url} color="blue" arrow={true} />
						</div>

						<div class="swiper-container-floorplans has-background-color-light-cream">
							<Swiper
								onSlideChange={() => {}}
								onSwiper={(swiper) => {}}
								modules={[Navigation]}
								navigation={{
									prevEl: navigationPrevRef.current,
									nextEl: navigationNextRef.current
								}}
								onBeforeInit={(swiper) => {
									swiper.params.navigation.prevEl = navigationPrevRef.current;
									swiper.params.navigation.nextEl = navigationNextRef.current;
								}}
							>
								{
									pohjakuvaCarouselItems.map((carouselItem) => {
										return(
											<SwiperSlide>
												<figure>
													<img src={carouselItem.image} alt="Jungmann facade drawing" width="324" height="276" loading="lazy" />
													<figcaption dangerouslySetInnerHTML={{ __html: carouselItem.caption }}></figcaption>
												</figure>
											</SwiperSlide>
										)
									})
								}
								<div class="swiper-button-prev" ref={navigationPrevRef}><img src={ArrowLeft} alt="" /></div>
								<div class="swiper-button-next" ref={navigationNextRef}><img src={ArrowRight} alt="" /></div>
							</Swiper>
						</div>

					</div>
				</div>
				
				<div class="swiper-container-images swiper-container-carousel">
					<Swiper
						spaceBetween={20}
						onSlideChange={() => {}}
						onSwiper={(swiper) => {}}
						modules={[Navigation]}
						slidesPerView="auto"
						autoHeight={true}
						navigation={{
							prevEl: navigationImagesPrevRef.current,
							nextEl: navigationImagesNextRef.current
						}}
						onBeforeInit={(swiper) => {
							swiper.params.navigation.prevEl = navigationImagesPrevRef.current;
							swiper.params.navigation.nextEl = navigationImagesNextRef.current;
						}}
						slidesOffsetBefore={20}
						breakpoints={{
							601: {
								spaceBetween: 40,
								slidesOffsetBefore: 40,
							},
							1001: {
								spaceBetween: 40,
								slidesOffsetBefore: 40,
							},
						}}
						lazy={{loadPrevNext: true}}
					>
						{
							data.carousel.map((carouselItem) => {
								return(
									<SwiperSlide><GatsbyImage image={carouselItem.childImageSharp.gatsbyImageData} alt="" /></SwiperSlide>
								)
							})
						}
						<div class="swiper-button-prev" ref={navigationImagesPrevRef}><img src={ImageArrowLeft} alt="" /></div>
						<div class="swiper-button-next" ref={navigationImagesNextRef}><img src={ImageArrowRight} alt="" /></div>
					</Swiper>
				</div>

			</div>
		</section>
	)
}

export default ImageTextCarousel